const $ = document.querySelector.bind(document);
const $$ = document.getElementById.bind(document);

class HHFilms {
    constructor() {
        this.api = 'https://dev.api.hearthappyfilms.com/';
        this.film_modal = null;
        this.success_modal = null;
        this.error_modal = null;
        this.modal_player = null;

        this.init_pancake_btn();
        this.init_film_modal_listener();
        this.init_hash_listener();
        this.init_modals();
        this.init_switch_navbar_listener();
        this.init_project_type_switcher();
        this.init_datepicker();
    }

    init_datepicker() {
        const project_date = document.querySelector("[name=project_date]");
        if (!project_date) return;
        this.fp = flatpickr(project_date, {
            enableTime: false,
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "m/d/Y",
            minDate: new Date(),
        });
    }

    init_film_modal_listener() {
        const add_listener = function(thumb) {
            thumb.addEventListener('click', e => {
                e.preventDefault();
                let video_id = e.target.dataset['filmId'];
                if (!video_id) {
                    console.log("Couldn't find video id!");
                    video_id = e.target.parentElement.dataset['filmId'];
                    if (!video_id) return console.log("Invalid video id: ", e.target);
                }
                this.film_modal.setContent('<div id="popup_video"></div>');
                this.film_modal.open();
                setTimeout(() => {
                    this.modal_player = new Vimeo.Player('popup_video', {
                        id: video_id,
                        width: Math.ceil(window.innerWidth - (window.window.innerWidth * 0.15)),
                        autoplay: true,
                        playsinline: false,
                        portrait: false,
                        title: false
                    });

                    this.modal_player.play().then(() => {});
                    this.modal_player.ready().catch(err => {
                        //console.log("FAIL!!!", err);
                        this.film_modal.close();
                        this.error_modal.setContent("<h1>Ah man...</h1><p>There was an error loading the video...</h1>");
                        this.error_modal.open();
                    });
                }, 0);
            });
        };

        document.querySelectorAll('.film_thumb').forEach(add_listener.bind(this));
        document.querySelectorAll('.package_thumb').forEach(add_listener.bind(this));
    }

    init_hash_listener() {
        window.addEventListener('hashchange', e => {
            const hash = location.hash.replace(/^#(.*)/, "$1");
            document.dispatchEvent(new CustomEvent('new_hash', {
                detail: {hash}
            }));
        });
    }

    init_modals() {
        this.film_modal = new tingle.modal({
            closeLabel: 'close',
            closeMethods: ['overlay', 'button', 'escape'],
            cssClass: ['film-modal'],
            onOpen() {
                //console.log('modal open');
            },
            onClose() {
                if (window.hhf.modal_player) {
                    window.hhf.modal_player.unload();
                } else {
                    console.log("WTF?");
                }
                console.log('modal closed');
            },
            beforeClose() {
                // e.g. save content before closing the modal
                return true; // close the modal
                return false; // nothing happens
            }
        });
        this.error_modal = new tingle.modal({
            footer: true,
            closeMethods: ['escape'],
        });
        this.error_modal.addFooterBtn('Dang, OK...', 'tingle-btn tingle-btn--danger', () => {
            this.error_modal.close();
        });
        this.success_modal = new tingle.modal({
            footer: true,
            closeMethods: ['escape'],
        });
        this.success_modal.addFooterBtn('Awesome!', 'tingle-btn tingle-btn--success', () => {
            this.success_modal.close();
        });
    }

    init_pancake_btn() {
        const flapjack = (e) => {
            e.stopPropagation();
            const controller = new AbortController();
            const hidePankcakeMenu = (ev) => {
                ev.stopPropagation();
                if (
                    ev.target.id === 'middle_nav' || 
                    (
                        ev.target.tagName === 'LI' &&
                        ev.target.parentElement.id === 'middle_nav'
                    )
                ) return;
                console.log('The Target ID: ', ev.target);
                $$('middle_nav').style.visibility = 'hidden';
                $$('pancake').addEventListener('click', flapjack, { capture: true, once: true });
                controller.abort();
            };

            if ($$('middle_nav').style.visibility === 'visible') {
                console.log('Hiding because visible');
                $$('middle_nav').style.visibility = 'hidden';
                $$('pancake').addEventListener('click', flapjack, { capture: true, once: true });
            } else {
                console.log('Showing because hidden');
                $$('middle_nav').style.visibility = 'visible';
                document.body.addEventListener('click', hidePankcakeMenu, { signal: controller.signal, capture: true });
            }
        }
        $$('pancake').addEventListener('click', flapjack, { capture: true, once: true });
    }

    init_switch_navbar_listener() {
        window.addEventListener("scroll", this.switch_navbar.bind(this), false);
    }

    // Handle toggling between different contact us forms on regular contact pages (not exclusive collections ones)
    init_project_type_switcher() {
        // Ignore this if the regular (non-collections) contact us form is not present
        if (!$('#contact_us_form')) return;

        const primary_contact = $('[name=primary_contact]');
        const secondary_contact = $('[name=secondary_contact]');
        const primary_location = $('[name=primary_location]');
        const secondary_location = $('[name=secondary_location]');
        const project_date = $('[name=project_date]');
        const email = $('[name=email]');
        const phone = $('[name=phone]');
        const message = $('[name=message]');
        const project_category = $('[name=project_category]');
        const specific_type = $('[name=specific_type]');
        let opts;
        if (specific_type && typeof specific_type === 'object' && 'options' in specific_type) opts = specific_type.options;

        const btns = ['wedding_btn','commercial_btn','family_btn','other_btn'];

        const activate_btn = function(btn) {
            btns.forEach(v => $$(v).classList.remove('active'));
            $$(btn).classList.add('active');
        }

        
        const optional_fields = [secondary_contact, primary_location, secondary_location];
        optional_fields.forEach(v => {
            if (v && typeof v === 'object' && 'style' in v) v.style.display = 'none';
        });

        // Change fields based on selected project type
        document.querySelectorAll('#primary_project_type_picker button').forEach(btn => {
            btn.addEventListener('click', e => {
                switch (e.target.id) {
                    case 'wedding_btn':
                        optional_fields.concat([specific_type]).forEach(v => v.style.display = 'block');
                        this.fp._input.style.display = 'block';
                        primary_contact.placeholder = '* Your Full Name';
                        secondary_contact.placeholder = "* Fiancé's Full Name";
                        primary_location.placeholder = '* Ceremony Location';
                        secondary_location.placeholder = '* Reception Location';
                        project_date.placeholder = this.fp._input.placeholder = '* Wedding Date';
                        message.placeholder = 'Tell us all about your wedding plans!';
                        phone.placeholder = 'Your Phone #';
                        email.placeholder = '* Your Email Address';

                        // Film type options
                        Array.from(opts).forEach(v => opts.remove(v));
                        [
                            {value: '', label: 'Choose Film Type...'},
                            {value: 'elopement', label: 'Elopement'},
                            {value: 'highlight_film', label: 'Highlight Film'},
                            {value: 'feature_film', label: 'Feature Film'},
                            {value: 'custom_wedding', label: 'Custom Wedding'},
                            {value: 'w_undecided', label: 'Undecided / Need More Info'},
                        ].forEach(v => {
                            const option = document.createElement("option");
                            option.text = v.label;
                            option.value = v.value;
                            opts.add(option);
                        });

                        project_category.value = 'wedding';
                        activate_btn('wedding_btn');
                        break;
                    case 'commercial_btn':
                        [primary_location, secondary_location, project_date].forEach(v => v.style.display = 'none');
                        [secondary_contact,specific_type].forEach(v => v.style.display = 'block');
                        this.fp._input.style.display = 'none';
                        secondary_contact.placeholder = '* Company Name';
                        primary_contact.placeholder = '* Primary Contact Full Name';
                        email.placeholder = '* Primary Contact Email';
                        phone.placeholder = '* Primary Contact Phone';
                        message.placeholder = 'Tell us all about your project!';

                        // Film type options
                        Array.from(opts).forEach(v => opts.remove(v));
                        [
                            {value: '', label: 'Choose Film Type...'},
                            {value: 'promo', label: 'Promotional Video (web)'},
                            {value: 'tv', label: 'Television Commercial'},
                            {value: 'social_media', label: 'Social Media Campaign Video'},
                            {value: 'corporate_event', label: 'Corporate Event'},
                            {value: 'c_other', label: 'Other...'},
                        ].forEach(v => {
                            const option = document.createElement("option");
                            option.text = v.label;
                            option.value = v.value;
                            opts.add(option);
                        });

                        project_category.value = 'commercial';
                        activate_btn('commercial_btn');
                        break;
                    case 'family_btn':
                        [secondary_location,secondary_contact].forEach(v => v.style.display = 'none');
                        [primary_location,specific_type].forEach(v => v.style.display = 'block');
                        this.fp._input.style.display = 'block';
                        primary_contact.placeholder = '* Your Full Name';
                        primary_location.placeholder = '* Project Location';
                        email.placeholder = '* Your Email Address';
                        phone.placeholder = 'Your Phone #';
                        project_date.placeholder = this.fp._input.placeholder = '* Project Date';
                        message.placeholder = 'We would LOVE to hear more about your project / event!';

                        // Film type options
                        Array.from(opts).forEach(v => opts.remove(v));
                        [
                            {value: '', label: 'Choose Film Type...'},
                            {value: 'birth_story', label: 'Birth Story'},
                            {value: 'day_of_birth', label: 'Day-Of Birth Story'},
                            {value: 'party', label: 'Family Celebration'},
                            {value: 'mitzvah', label: 'Bar/Bat Mitzvah'},
                            {value: 'lifestyle', label: 'Day-in-the-Life'},
                            {value: 'f_other', label: 'Other...'},
                        ].forEach(v => {
                            const option = document.createElement("option");
                            option.text = v.label;
                            option.value = v.value;
                            opts.add(option);
                        });

                        project_category.value = 'family';
                        activate_btn('family_btn');
                        break;
                    case 'other_btn':
                        optional_fields.concat([specific_type]).forEach(v => v.style.display = 'none');
                        this.fp._input.style.display = 'block';
                        project_date.placeholder = this.fp._input.placeholder = '* Project Date';
                        message.placeholder = 'We would LOVE to hear more about your project / event!';

                        project_category.value = 'other';
                        activate_btn('other_btn');
                        break;
                }
            });
        });

        setTimeout(() => {
            const btn = $$('other_btn');
            if (btn) btn.click();
        }, 0);
    }

    switch_navbar() {
        const embed_container = document.querySelector('.embed_container');
        if (!embed_container) return;
        const navbar = document.getElementById("main_nav");
        if (window.scrollY > embed_container.offsetHeight - 50) {
            navbar.classList.add('opaque_nav')
        } else {
            navbar.classList.remove('opaque_nav');
        }
    }
}

function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        let context = this,
            args = arguments;
        let later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

function verify_hhf_captcha() {
    const form = document.querySelector('form');
    const submit_btn = document.getElementById('submit_contact_form');
    const loader = document.getElementById('contact_form_loader');
    const data = new FormData(form);

    // Show Loader / Hide Submit Button
    submit_btn.style.display = 'none';
    loader.style.display = 'block';

    const xhr = new XMLHttpRequest();
    xhr.addEventListener("load", () => {
        // Hide Loader / Show Submit Button
        submit_btn.style.display = 'block';
        loader.style.display = 'none';

        if (xhr.status === 200) {
            // Reset the form
            form.reset();

            hhf.success_modal.setContent(`<h1>Message Sent!</h1><p>We'll shoot you a personal email as soon as we see your message come through. You should also receive an automated confirmation email.</p><p>If you don't get that automated email or hear back from us within the next day or so, please contact us directly at <a href="mailto:info@hearthappyfilms.com">info@hearthappyfilms.com</a>.</p><p>Thanks so much!!</p>`);
            hhf.success_modal.open();
        } else {
            const default_msg = `<p>It looks like we're having some trouble getting your message. Try again, but, if you see this again, just contact us directly: <a href="mailto:info@hearthappyfilms.com">info@hearthappyfilms.com</a></p><p>Computers sometimes fail us, but, we're never gonna give you up, never gonna let you down, never run around and desert you. Never gonna make you cry (unless its for happy things), never gonna say goodbye (forever), never gonna tell a lie and hurt you.</p>`;
            let response = null;
            try {
                response = JSON.parse(xhr.responseText);
                hhf.error_modal.setContent(`<h1>Oops!</h1>${(response.outcome === 'error' ? `<p>${response.error}</p>` : default_msg)}`);
            } catch (e) {
                console.log("Invalid JSON.", e);
                hhf.error_modal.setContent(`<h1>Oops!</h1>${default_msg}`);
            }
            hhf.error_modal.open();
        }
        grecaptcha.reset();
    });
    xhr.addEventListener('error', event => {
        this.error_modal.setContent(`<h1>Oops!</h1><p>Something isn't working right. Please contact us directly:</p><p><a href="mailto:info@hearthappyfilms.com">info@hearthappyfilms.com</a></p>`);
        this.error_modal.open();
        grecaptcha.reset();
    });
    xhr.open("POST", form.action, true);
    xhr.send(data);
}

document.addEventListener("DOMContentLoaded", () => {
    window.hhf = new HHFilms();
});
